<template>
  <div>
    <hero-bar>
      Cadastro produto
      <!-- <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link> -->
    </hero-bar>

    <section class="section">
    <!-- <section class="section"> -->

      <form @submit.prevent="cadastroProdutos">
        <card-component :title="prod" icon="ballot" v-for="(prod, index) in produtosPendentes" :key="index">
          <input type="hidden" :value="insertCod(prod, index)">
          <b-field grouped>
            <b-field expanded label="Descricao_PTBR">
              <b-input size="is-small" required v-model="novosProdutos[index].Descricao_PTBR" />
            </b-field>

            <b-field expanded label="Descricao_EN">
              <b-input size="is-small" required v-model="novosProdutos[index].Descricao_EN" />
            </b-field>

            <b-field expanded label="Descricao_NF">
              <b-input size="is-small" required v-model="novosProdutos[index].Descricao_NF" />
            </b-field>
          </b-field>

          <b-field grouped>
              <b-field expanded label="Unidade Comercializada">
                <b-input size="is-small" required v-model="novosProdutos[index].Unidade_Comercializada" />
              </b-field>

              <b-field expanded label="Linha Catálago">
                <b-input size="is-small" required v-model="novosProdutos[index].Linha_Catalago" />
              </b-field>

              <b-field expanded label="Descricao_Certificado">
                <b-input size="is-small" required v-model="novosProdutos[index].Descricao_Certificado" />
              </b-field>
          </b-field>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field expanded label="Cod_Unid_Qtde">
                    <b-input size="is-small" required type="number" v-model="novosProdutos[index].Cod_Unid_Qtde" />
                  </b-field>
                </div>
                <div class="column">
                  <b-field expanded label="Cod_Unid_Peso">
                    <b-input size="is-small" required type="number" v-model="novosProdutos[index].Cod_Unid_Peso" />
                  </b-field>
                </div>
                <div class="column">
                  <b-field expanded label="NCM">
                    <b-input size="is-small" type="number" required v-model="novosProdutos[index].NCM" />
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field expanded label="Necessita_LI">
                    <b-input size="is-small" required type="number" v-model="novosProdutos[index].Necessita_LI" />
                  </b-field>
                </div>
                <div class="column">
                  <b-field expanded label="Preco">
                    <b-input size="is-small" required type="number" v-model="novosProdutos[index].Preco" />
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </card-component>
        <div class="control">
          <b-button native-type="submit" type="is-primary" :loading="isLoading">Cadastrar Produtos</b-button>
        </div>
      </form>

    </section>
  </div>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'CadastroProduto',
  components: {
    HeroBar,
    CardComponent
  },
  data () {
    return {
      novosProdutos: [],
      produtosPendentes: [],
      isLoading: false
    }
  },
  methods: {
    objProduto () {
      return {
        Descricao_PTBR: null,
        Descricao_EN: null,
        Descricao_NF: null,
        Codigo_Produto: null,
        NCM: null,
        Unidade_Comercializada: null,
        Linha_Catalago: null,
        Cod_Unid_Qtde: null,
        Cod_Unid_Peso: null,
        Descricao_Certificado: null,
        Necessita_LI: null,
        Preco: null,
        IDCliente: this.body.IDCliente
      }
    }
  }
}
</script>
