<template>
  <div>
    <hero-bar>
      {{ heroTitle }}
      <b-button slot="right" @click="toggleEdit" class="button">
        Editar produto
      </b-button>
      <b-button slot="right"
        label="Deletar produto"
        @click="confirmDelete($route.params.idProduto)"
        type="is-danger"
        class="ml-2"
      />
    </hero-bar>
    <section class="section is-main-section">
        <b-breadcrumb align="is-left">
          <b-breadcrumb-item tag='router-link' to="/">Home</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' to="/clientes">Clientes</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}`">Detalhe cliente</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}/produtos`">Produtos</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}/produtos/${$route.params.idProduto}`" active>Detalhe produto</b-breadcrumb-item>
        </b-breadcrumb>

        <card-component
          title="Detalhes do Produto"
          icon="account"
          class="tile is-child"
        >
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
          <div v-if="!isLoading">
            <div class="columns">
              <div v-if="hasImage" class="column is-4">
                <figure class="image is-1256x256">
                  <img :src="produtoDetalhes.Imagem">
                </figure>
              </div>
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <b-field label="Código do Produto">
                      <b-input :value="produtoDetalhes.Codigo_Produto" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Referência do Processo">
                      <b-input :value="produtoDetalhes.Descricao_PTBR" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Linha Catálago">
                      <b-input :value="produtoDetalhes.Linha_Catalago" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="NCM">
                      <b-input :value="produtoDetalhes.NCM" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Descricao EN">
                      <b-input :value="produtoDetalhes.Descricao_EN" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                    </b-field>
                  </div>
                </div>

              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Descrição Nota fiscal">
                  <b-input :value="produtoDetalhes.Descricao_NF" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                </b-field>
                <b-field label="Unidade Medida Comercializada">
                  <b-input :value="produtoDetalhes.Unidade_Medida_Comercializada" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Descricao PTBR">
                  <b-input :value="produtoDetalhes.Descricao_PTBR" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                </b-field>
                <b-field label="Cod Unid Peso">
                  <b-input :value="produtoDetalhes.Cod_Unid_Peso" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                </b-field>
                <b-field v-if="produtoDetalhes.DTCreated" label="DTCreated">
                  <b-input :value="produtoDetalhes.DTCreated" :custom-class="editMode ? '' : 'is-static'" :readonly="!editMode" />
                </b-field>

              </div>
            </div>
          </div>
        </card-component>
        <div class="is-flex is-justify-content-flex-end" v-if="editMode">
          <b-button @click="toggleEdit" type="is-danger" :disabled="isLoadingEdit" class="button mt-4 mr-2">Cancelar edição</b-button>
          <b-button @click="AtualizaProduto" type="is-info" :loading="isLoadingEdit" class="mt-4">Atualizar produto</b-button>
        </div>
    </section>

  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import {
  formatCNPJ,
  removerProduto,
  detalheProduto,
  notificationError,
  parseStatus,
  formatDate,
  atualizaProduto,
  reponseHandler
} from '@/core'

export default {
  name: 'ProdutoDetalhes',
  components: {
    CardComponent,
    HeroBar
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingEdit: false,
      produtoDetalhes: {},
      editMode: false,
      copyProduct: {}
    }
  },
  computed: {
    heroTitle () {
      if (this.produtoDetalhes && this.codProduto) {
        return 'Produto: ' + this.codProduto
      } else {
        return 'Detalhe produto'
      }
    },
    codProduto () {
      return this.produtoDetalhes.Codigo_Produto
    },
    hasImage () {
      if (this.produtoDetalhes.Imagem && this.produtoDetalhes.Imagem.length) return true
      return false
    }
  },
  methods: {
    formatDate (v) { return formatDate(v) },
    formatCNPJ (val) { return formatCNPJ(val) },
    parseStatus (statusCode) { return parseStatus(statusCode) },
    getDetalhes () {
      this.isLoading = true
      detalheProduto(this.$route.params)
        .then((res) => {
          reponseHandler(res, () => {
            this.produtoDetalhes = res.dados
            this.isLoading = false
          })
        })
        .catch(() => {
          this.produtoDetalhes = {}
          notificationError(this.$buefy.notification)
          this.isLoading = false
        })
    },
    confirmDelete (id) {
      this.$buefy.dialog.confirm({
        title: 'Deletar Produto',
        message: `Tem certeza que deseja deletar ${this.codProduto}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deletarProduto(id)
      })
    },
    deletarProduto (id) {
      removerProduto(id)
        .then(res => {
          setTimeout(() => {
            this.$router.push(`/clientes/${this.$route.params.id}/produtos`)
          }, 3000)
        })
        .catch(() => {
          notificationError(this.$buefy.notification)
        })
    },
    toggleEdit () {
      this.editMode = !this.editMode
      if (!this.editMode) {
        this.produtoDetalhes = false
        setTimeout(() => this.getDetalhes(), 0)
      }
    },
    AtualizaProduto () {
      if (this.editMode) {
        this.editMode = false
        this.isLoadingEdit = true
        atualizaProduto(this.produtoDetalhes)
          .then(res => {
            setTimeout(() => this.getDetalhes(), 500)
            this.isLoadingEdit = false
          })
          .catch(error => {
            notificationError(this.$buefy.notification, error.message)
            this.isLoadingEdit = false
          })
      }
    }
  },
  mounted () {
    this.getDetalhes()
  }
}
</script>
